
import { defineComponent, ref } from 'vue';
import { list, ListHeaderItem } from '@/components/common/list';
import BillingInformation from '@/components/view/common/dialog/billing-information';
import { userForSuperApi } from '@/api';

interface PmAppInfoType {
    Project: string;
    Username: string;
    ExpireTime: string;
    ID: string;
}

interface PMInfoType {
    Name: string;
    Email: string;
    Installer: string;
    Project: string;
    CreateTime: string;
    PmAppInfo: Array<PmAppInfoType>;
}
export default defineComponent({
    components: {
        list,
        BillingInformation
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const infoItem = [{
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName
        }, {
            name: 'Email',
            label: WordList.TabelPropertyManageEmail
        }, {
            name: 'Installer',
            label: WordList.ProperAllTextInstaller
        }, {
            name: 'Project',
            label: WordList.ProperAllTextProject
        }, {
            name: 'CreateTime',
            label: WordList.CreateTime
        }];

        const headers: Array<ListHeaderItem> = [{
            name: 'Project',
            label: WordList.ProperAllTextProject
        }, {
            name: 'Username',
            label: WordList.UserName
        }, {
            name: 'ExpireTime',
            label: WordList.TabelExpretimeBoxSpecificTime
        }, {
            name: 'Action',
            label: WordList.CaptureListTanleAction,
            type: 'customize'
        }];

        const infoDetail = ref<PMInfoType>({} as PMInfoType);
        const updateList = ref(0);
        userForSuperApi.getPMUserInfo({
            UUID: decodeURIComponent(props.id)
        }, (data: PMInfoType) => {
            infoDetail.value = data;
            updateList.value += 1;
        });

        const isShowBillInfo = ref(false);
        const userId = ref('');
        const getBillingInfo = (id: string) => {
            userId.value = id;
            isShowBillInfo.value = true;
        };

        return {
            infoItem,
            headers,
            getBillingInfo,
            isShowBillInfo,
            userId,
            infoDetail,
            updateList
        };
    }
});
